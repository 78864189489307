<template>
  <div>
    <el-dialog
      v-if="dialogVisible"
      :title="txtType.title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="400px"
    >
      <div class="fx">
        <div class="futle txtds">
          <div>{{ txtType.onsed }}：</div>
          <div>{{ txtType.twosed }}</div>
          <div style="margin-top: 20px">{{ txtType.onsetd }}：</div>
          <div>联系{{ Itemdata.idCardName }}（{{ Itemdata.idcardNo }}）</div>
          <div>{{ txtType.conster }}</div>
        </div>
        <div class="fx-1" v-if="visible">
          <div id="qrcode"></div>
        </div>
      </div>
      <div slot="footer" class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="copyed(Itemdata.signUrl)"
          >复制链接</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import {
  getSignUrl, //获取征信授权书等签约地址
} from "@/api/apiAll/phpUrl.js";
export default {
  name: "",
  props: {
    linkId: {
      type: String,
      default: "",
    },
    linkType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      qrCodeInstance: null, // 用于存储QRCode实例

      dialogVisible: false,
      Itemdata: {
        incomingStatus: "", //审核状态
        signUrl: "",
        idCardName: "", //身份证上姓名
        idcardNo: "", //身份证号码
        creditLimit: 0, //授信额度
      },
      txtType: {
        title: "", //标题
        onsed: "", //
        twosed: "", //
        onsetd: "", //
        conster: "", //
      },
    };
  },
  created() {},
  methods: {
    openDialog(url) {
      this.visible = true;
      this.generateQRCode(url);
    },
    generateQRCode(url) {
      if (this.qrCodeInstance) {
        this.qrCodeInstance.clear(); // 清除旧的二维码
      }
      const options = {
        text: url,
        width: 150,
        height: 130,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      };
      this.$nextTick((_) => {
        this.qrCodeInstance = new QRCode(
          document.getElementById("qrcode"),
          options
        );
      });
    },
    closeDialog() {
      this.visible = false;
      if (this.qrCodeInstance) {
        this.qrCodeInstance.clear(); // 清除二维码
        this.qrCodeInstance = null;
      }
    },
    init() {
      getSignUrl({ id: this.linkId }).then(async (res) => {
        if (res.code == 200) {
          Object.assign(this.Itemdata, res.data);
          //授权链接
          if (this.linkType == "souquan") {
            this.txtType.title = "授权链接";
            this.txtType.onsed = "授权提示";
            this.txtType.twosed =
              "客户的证件资料已提交申请，需要联系客户进行授权认证";
            this.txtType.onsetd = "授权操作";
            this.txtType.conster = "通过此网页进行人脸识别授权";
          }
          //签约链接
          if (this.linkType == "qianyue") {
            this.txtType.title = "签约链接";
            this.txtType.onsed = "签约提示";
            this.txtType.twosed =
              "客户保理申请通过，批复额度¥" +
              (this.Itemdata.creditLimit || "0.00") +
              "，请联系客户进行签约";
            this.txtType.onsetd = "签约操作";
            this.txtType.conster = "通过此网页进行人脸识别签约";
          }
          //绑卡链接
          if (this.linkType == "bangka") {
            this.txtType.title = "银行绑卡";
            this.txtType.onsed = "开卡提示";
            this.txtType.twosed = "客户需要绑定银行卡进行还款";
            this.txtType.onsetd = "签约操作";
            this.txtType.conster = "通过此网页进行绑卡";
          }
          //重置密码
          if (this.linkType == "conzhi") {
            this.txtType.title = "密码重置";
            this.txtType.onsed = "密码提示";
            this.txtType.twosed = "找回客户在盈众的支付密码";
            this.txtType.onsetd = "签约操作";
            this.txtType.conster = "通过此网页进行人脸识别找回";
          }
          this.dialogVisible = true;
          await this.openDialog(this.Itemdata.signUrl);
        }
      });
    },
    copyed(txt) {
      // 创建输入框元素
      let oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = txt;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$message.success("复制成功");
      // 复制后移除输入框
      oInput.remove();
    },
  },
};
</script>
<style lang='scss' scoped>
.txtds {
  font-size: 14px;
  width: 190px;
  margin-right: 10px;
  line-height: 18px;
}
.imgeds {
  width: 150px;
  height: 130px;
}
</style>