<template>
  <div class="aftersales main">
    <div class="merch-card scorr-roll">
      <div class="Search">
        <div>
          <el-form
            ref="queryForm"
            :model="queryParams"
            :inline="true"
            @keyup.enter.native="search"
          >
            <el-form-item label="进件编号：">
              <el-input
                v-model="queryParams.factoringEntryCode"
                placeholder="请输入白条编号"
                clearable
              />
            </el-form-item>
            <el-form-item label="采购商编号：">
              <el-input
                v-model="queryParams.merchantCode"
                placeholder="请输入保理方的采购商编号"
                clearable
              />
            </el-form-item>
            <el-form-item label="单位名称：">
              <el-input
                v-model="queryParams.enterprise"
                placeholder="请输入单位名称"
                clearable
              />
            </el-form-item>
            <el-form-item label="申请人：">
              <el-input
                v-model="queryParams.applicant"
                placeholder="请输入申请人姓名"
                clearable
              />
            </el-form-item>
            <el-form-item label="身份证号：">
              <el-input
                v-model="queryParams.idcardNo"
                placeholder="请输入身份证号"
                clearable
              />
            </el-form-item>
            <el-form-item label="账户状态：">
              <el-select
                clearable
                v-model="queryParams.accountStatus"
                placeholder="请选择账户状态"
              >
                <el-option label="正常" :value="1"></el-option>
                <el-option label="注销" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据来源：">
              <el-select
                clearable
                v-model="queryParams.sources"
                placeholder="请选择数据来源"
              >
                <el-option label="客户自主进件" :value="1"></el-option>
                <el-option label="业务员代进件" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开通状态：">
              <el-select
                clearable
                v-model="queryParams.incomingStatus"
                placeholder="请选择开通状态"
              >
                <el-option
                  v-for="(item, index) in incomingList"
                  :label="item"
                  :value="index"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="search()" class="marle_10"
                >查 询</el-button
              >
              <el-button type="primary" @click="resets()" class="marle_10"
                >重 置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!--筛选列表-->
        <div class="sign">
          <!-- 左边 -->
          <div class="rightBtn">
            <div style="display: flex">
              <div
                v-for="(item, index) in countData"
                :key="index"
                class="defaultStyle"
                :class="
                  queryParams.tabIncomingStatus === item.tabIncomingStatus
                    ? 'opp'
                    : ''
                "
                @click="setLinds(item)"
              >
                {{ item.tabIncomingStatusStr }}
                <span v-if="item.tabIncomingStatusStr !== '' && item.count"
                  >({{ item.count }})</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 表格数据 -->
        <div class="tableData">
          <el-table
            border
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{ background: '#F2F3F5' }"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
              :index="indexMethod"
              width="70"
            />
            <el-table-column
              align="center"
              label="进件编号"
              prop="factoringEntryCode"
            />
            <el-table-column
              align="center"
              label="采购商编号"
              prop=""
              width="120px"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.merchantCode || "-" }}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="单位名称"
              prop="enterprise"
            />
            <el-table-column align="center" label="单位编号" prop="userId" />
            <el-table-column
              align="center"
              label="申请人"
              prop="applicant"
              width="120px"
            />
            <el-table-column align="center" label="身份证号" prop="idcardNo" />
            <el-table-column align="center" label="是否法人" prop="">
              <template slot-scope="scope">
                <div>{{ scope.row.isLegalPerson == 1 ? "是" : "否" }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="授信额度" prop="">
              <template slot-scope="scope">
                <div>{{ scope.row.creditLimit || "-" }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="账户状态" prop="">
              <template slot-scope="scope">
                <div>{{ scope.row.accountStatusStr || "-" }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="开通状态" prop="">
              <template slot-scope="scope">
                <div>{{ scope.row.incomingStatusStr }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="数据来源" prop="">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.sources == 1 ? "客户自主进件" : "业务员代进件" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="最后操作人"
              prop="operator"
              width="100px"
            />
            <el-table-column
              align="center"
              label="最后操作日期"
              prop="operationTime"
              width="180px"
            />
            <el-table-column align="center" label="操作" width="200" prop="">
              <template slot-scope="scope">
                <span v-if="scope.row.accountStatus != 2">
                  <span
                    class="btsed"
                    @click="Voidlica(scope.row)"
                    v-if="[-1, 0, 1].includes(scope.row.incomingStatus)"
                    >作废申请</span
                  >
                  <span
                    class="btsed"
                    v-if="[2].includes(scope.row.incomingStatus)"
                    @click="Supplinfor(scope.row)"
                    >补充资料</span
                  >
                  <span
                    class="btsed"
                    v-if="[0].includes(scope.row.incomingStatus)"
                    @click="Authorlink(scope.row, 'souquan')"
                    >授权链接</span
                  >
                  <span
                    class="btsed"
                    v-if="[5].includes(scope.row.incomingStatus)"
                    @click="Authorlink(scope.row, 'qianyue')"
                    >签约链接</span
                  >
                  <!-- <span
                  class="btsed"
                  v-if="[5].includes(scope.row.incomingStatus)"
                  @click="Authorlink(scope.row, 'bangka')"
                  >绑卡链接</span
                > -->
                  <span
                    class="btsed"
                    v-if="[6].includes(scope.row.incomingStatus)"
                    @click="Authorlink(scope.row, 'conzhi')"
                    >重置密码</span
                  >
                </span>
                <span class="btsed" @click="Detainfor(scope.row)"
                  >详细资料</span
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.page"
            :limit.sync="queryParams.perPage"
            @pagination="getList"
          />
        </div>
      </div>
    </div>
    <!-- 二维码链接弹窗 -->
    <Alindiage ref="alinds" :linkId="linkId" :linkType="linkType" />
    <!-- 补充店铺资料 -->
    <el-dialog
      title="补充店铺资料"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      style="margin-top: -10vh"
      v-if="dialogVisible"
    >
      <el-form ref="form" :model="subForm" label-width="120px" :rules="rule">
        <el-form-item label="经营地址" prop="businessAddress">
          <el-input
            v-model="subForm.businessAddress"
            placeholder="请输入经营地址"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺面积" prop="companyArea">
          <el-input
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
            v-model="subForm.companyArea"
            placeholder="请输入店铺的大概面积"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="房屋产权" prop="propertyType">
          <el-radio-group v-model="subForm.propertyType">
            <el-radio :label="1">自有</el-radio>
            <el-radio :label="0">租赁</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="房屋租金"
          prop="rental"
          v-if="subForm.propertyType == 0"
        >
          <el-input
            v-model="subForm.rental"
            placeholder="请输入每月房屋租金"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="人工成本" prop="laborCost">
          <el-input
            v-model="subForm.laborCost"
            placeholder="请输入每月的人工成本"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="员工数量" prop="personNumber">
          <el-input
            v-model="subForm.personNumber"
            placeholder="请输入员工数量"
            clearable
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
            maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item label="医保开通" prop="medicalInsurance">
          <el-radio-group v-model="subForm.medicalInsurance">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="紧急联系人" prop="emergencyContactName">
          <el-input
            v-model="subForm.emergencyContactName"
            placeholder="请输入紧急联系人姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人关系" prop="emergencyContactRelation">
          <el-select
            v-model="subForm.emergencyContactRelation"
            placeholder="请选择和联系人关系"
            clearable
          >
            <el-option label="父母" value="父母"></el-option>
            <el-option label="配偶" value="配偶"></el-option>
            <el-option label="子女" value="子女"></el-option>
            <el-option label="兄弟姐妹" value="兄弟姐妹"></el-option>
            <el-option label="朋友" value="朋友"></el-option>
            <el-option label="同事" value="同事"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人电话" prop="emergencyContactPhone">
          <el-input
            v-model="subForm.emergencyContactPhone"
            placeholder="请输入紧急联系人电话"
            clearable
          ></el-input>
        </el-form-item>
        <div v-if="[5, 6, 8].includes(subForm.userType)">
          <el-form-item label="机构名称" prop="practiceName">
            <el-input
              v-model="subForm.practiceName"
              placeholder="请输入机构名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="登记号" prop="practiceSerialNumber">
            <el-input
              v-model="subForm.practiceSerialNumber"
              placeholder="请输入登记号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="法人姓名" prop="practiceLegalPerson">
            <el-input
              v-model="subForm.practiceLegalPerson"
              placeholder="请输入法人姓名"
              clearable
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="supplinbtns" :loading="btnloading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Alindiage from "./components/alindiage.vue";
import { createNamespacedHelpers } from "vuex";
import {
  factoringCreditlist, //保理白条客户授信记录列表
  factoringCreditcancel, //作废申请
  factoringCreditreplenish, //补充资料
  getStatusDropDown, //获取进件审核状态下拉列表
  obtainFactoringAccountInfo, //获取保理进件资料
} from "@/api/apiAll/phpUrl.js";
import {
  getAveragePrice, //获取用户当地均价和用户的经营地址
} from "@/api/apiAll/javaUrl.js";
export default {
  name: "Aftersales",
  data() {
    return {
      loading: false, //数据加载动画
      btnloading: false, //按钮loading
      // 点击标记
      countData: [],
      queryParams: {
        page: 1,
        perPage: 10,
        factoringEntryCode: "", //进件编号
        merchantCode: "", //采购商编号
        enterprise: "", //单位名称
        applicant: "", //申请人
        idcardNo: "", //身份证号
        accountStatus: "", //账户状态 1正常 0逾期
        sources: "", //数据来源 1客户自主进件 2业务员代进件
        incomingStatus: "", //开通状态
        tabIncomingStatus: "", //白条状态
      },
      incomingList: [],
      total: 0,
      tableData: [],

      dialogVisible: false,
      subForm: {
        id: "",
        ocrBusinessPracticeLicense: "", //医疗执业许可证ocr识别id
        businessAddress: "", //经营地址
        companyArea: "", //店铺面积
        propertyType: 0, //房屋产权(0 : 租赁, 1: 自有产权)
        rental: "", //房屋租金
        laborCost: "", //人工成本
        personNumber: "", //员工数量
        medicalInsurance: 1, //医保开通
        emergencyContactName: "", //紧急联系人
        emergencyContactRelation: "", //联系人关系
        emergencyContactPhone: "", //联系人电话
        practiceName: "", //机构名称
        practiceSerialNumber: "", //登记号
        practiceLegalPerson: "", //法人姓名
        userType: "", //单位类型：1	单体药店 2	连锁总部 3	连锁直营 4	连锁加盟 5	诊所 6	卫生室 7	批发(商业) 8	医院
      },
      subPrice: {
        rental: 0, //当地的店铺平均成本（默认返回 -1) （单位：平米）
        laborCosts: 0, //当地的人工成本（默认返回 -1)（*单位：元/人）
        businessAddress: "", //经营地址
      },
      linkId: "",
      linkType: "",
      rule: {
        businessAddress: [
          { required: true, message: "经营地址不能为空", trigger: "blur" },
        ],
        companyArea: [
          { required: true, message: "店铺面积不能为空", trigger: "blur" },
        ],
        propertyType: [
          { required: true, message: "请选择房屋产权", trigger: "change" },
        ],
        rental: [
          { required: true, message: "房屋租金不能为空", trigger: "blur" },
        ],
        laborCost: [
          { required: true, message: "人工成本不能为空", trigger: "blur" },
        ],
        personNumber: [
          { required: true, message: "员工数量不能为空", trigger: "blur" },
        ],
        medicalInsurance: [
          { required: true, message: "请选择是否开通医保", trigger: "change" },
        ],
        emergencyContactName: [
          { required: true, message: "紧急联系人不能为空", trigger: "blur" },
        ],
        emergencyContactRelation: [
          { required: true, message: "请选择和联系人关系", trigger: "change" },
        ],
        emergencyContactPhone: [
          { required: true, message: "联系人电话不能为空", trigger: "blur" },
        ],
        practiceName: [
          { required: true, message: "机构名称不能为空", trigger: "blur" },
        ],
        practiceSerialNumber: [
          { required: true, message: "登记号不能为空", trigger: "blur" },
        ],
        practiceLegalPerson: [
          { required: true, message: "法人姓名不能为空", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    Alindiage,
  },
  computed: {},
  watch: {
    "subForm.companyArea": {
      handler(newVal) {
        if (this.subPrice.rental > 0) {
          this.subForm.rental = this.subPrice.rental * newVal;
        }
      },
      deep: true,
    },
    "subForm.personNumber": {
      handler(newVal) {
        if (this.subPrice.laborCosts > 0) {
          this.subForm.laborCost = this.subPrice.laborCosts * newVal;
        }
      },
      deep: true,
    },
  },
  async created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    let res = await getStatusDropDown();
    this.incomingList = res.data;
    await this.getList();
  },
  methods: {
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    },
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    //** 列表数据查询 */
    getList() {
      this.loading = true;
      factoringCreditlist(this.queryParams).then((res) => {
        this.tableData = res.data.data;
        this.countData = res.data.countData;
        this.total = res.data.total;
        this.loading = false;
      });
      this.setquery();
    },
    /** 查询 */
    search() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置 */
    resets() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getList();
    },
    /** 切换状态 */
    setLinds(item) {
      this.queryParams.tabIncomingStatus = item.tabIncomingStatus;
      this.search();
    },
    /** 授权链接 */
    async Authorlink(row, key) {
      this.linkId = String(row.id);
      this.linkType = key;
      await this.$nextTick((_) => {
        this.$refs.alinds.init();
      });
    },
    /** 补充资料弹窗 */
    async Supplinfor(row) {
      await Object.assign(this.subForm, this.$options.data().subForm);
      this.subForm.id = row.id;
      this.subForm.ocrBusinessPracticeLicense = row.ocrBusinessPracticeLicense;
      this.subForm.userType = row.userType;
      await this.getAverds(row);
      await this.getobtaineds(row);
      this.dialogVisible = true;
    },
    /** 获取用户当地均价和用户的经营地址 */
    async getAverds(row) {
      var res = await getAveragePrice({ userId: row.userId });
      Object.assign(this.subPrice, res.content);
    },
    /** 获取详情 */
    async getobtaineds(row) {
      var res = await obtainFactoringAccountInfo({ id: row.id });
      var data = { ...res.data };
      if (data.businessAddress == "") {
        data.businessAddress = this.subPrice.businessAddress;
      }
      Object.assign(this.subForm, data);
    },
    /** 补充资料确认 */
    supplinbtns() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnloading = true;
          factoringCreditreplenish(this.subForm).then((res) => {
            this.btnloading = false;
            if (res.code == 200) {
              this.$message.success("补充资料成功");
              this.dialogVisible = false;
              this.getList();
            }
          });
        }
      });
    },
    /** 作废申请 */
    Voidlica(row) {
      this.$confirm(
        `<div>确认作废申请？</div><div style='color: #F53F3F'>作废后，客户将重新进行资料进件</div>`,
        "作废申请",
        {
          closeOnClickModal: false,
          cancelButtonText: "取消",
          confirmButtonText: "确认",
          type: "warning",
          showClose: false,
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          factoringCreditcancel({ id: row.id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("操作成功");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    /** 详细资料 */
    Detainfor(row) {
      this.$router.push({
        path: "/DetInformation",
        query: { id: row.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: black;
}
::v-deep .el-checkbox__label {
  color: black;
}
::v-deep .el-badge__content {
  border-radius: 0px;
  padding: 0 3px;
  color: black;
}
::v-deep .el-input--small .el-input__inner {
  width: 145px;
}

::v-deep .el-badge__content.is-fixed {
  right: 30px;
}
::v-deep .el-input .el-input__inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    min-width: 1000px;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
  }
  .inputTop {
    margin-bottom: 10px;
  }
  .dateQuery {
    // width: 1220px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    .queryTitle,
    .fterText {
      font-size: 14px;
      margin-right: 10px;
    }
    .fterText {
      margin-left: 20px;
    }
    .searchBtn {
      margin-left: 20px;
    }
  }
  .sign {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .paging {
    margin-top: 10px;
    text-align: right;
  }
  .opp {
    color: #fff;
    border-radius: 5px;
    background-color: #06b7ae;
  }
  .defaultStyle {
    padding: 10px 20px;
    text-align: center;
    line-height: 100%;
    font-size: 14px;
    cursor: pointer;
  }
  .tail {
    display: flex;
    justify-content: flex-end;
  }
}

.Search {
  width: 100%;
  .search-box {
    display: flex;
    flex-wrap: wrap;
  }
  .sch-1 {
    margin-top: 10px;
    // width: 250px;
    min-width: 250px;
    display: flex;
    .sch-title {
      width: 40%;
      min-width: 80px;
      height: 100%;
      font-size: 13px;
      background-color: #f5f7fa;
      color: #909399;
      border: 1px solid #dcdfe6;
      border-right: none;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 0px 0px 5px !important;
    }
  }
  .sch-2 {
    margin-right: 2%;
  }
}

p {
  margin: 0;
  padding: 0;
}
.justLook {
  margin-left: 50px;
}
::v-deep .el-select {
  width: 100%;
}
</style>
